import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import "../FilterRow.scss";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import {
  FilterItem,
  SubGroupFilterItem,
} from "pages/TargetV2/types/targetV2.types";
import SelectSimpleFilter from "../SelectSimpleFilter";
import "./SurveyFilterRow.scss";
import MultiSimpleSelectFilter from "../MultiSimpleSelectFilter";
import DropDownSelectFilter from "../dropdownSelectFilter/DropDownSelectFilter";
import { DraggableStateSnapshot } from "react-beautiful-dnd";

type SurveyFilterNewRowProps = {
  name: string;
  filtersSelected: Array<string>;
  filters: Array<string>;
  id: number;
  index: number;
  level: number;
  isMandatory?: boolean;
  snapchot: DraggableStateSnapshot;
  isResponsive: boolean;
  parentItem: SubGroupFilterItem;
  filterItem: FilterItem;
  width?: string;
  style?: React.CSSProperties | undefined;
  isQuestion?: boolean;
};

const SurveyFilterNewRow: FC<SurveyFilterNewRowProps> = ({
  name,
  filtersSelected,
  filters,
  id,
  index,
  level,
  isMandatory,
  snapchot,
  isResponsive,
  parentItem,
  filterItem,
  width,
  style,
  isQuestion,
}) => {
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);

  const {
    handleUpdateFilterSelected,    
    handleUpdateSurveyNameFilterSelected,
  } = useTargetV2Context();

  const refDropDownSurvey = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        refDropDownSurvey.current &&
        !refDropDownSurvey.current.contains(e.target)
      ) {
        setIsExpandFilter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandFilter]);

  const handleExpandFilter = useCallback(() => {
    setIsExpandFilter((state) => !state);
  }, []);

  const handleUpdateFilterClick = useCallback(
    (filter: string) => {
      let action: "Add" | "Remove" = "Add";

      if (
        filterItem.filtersSelected.some(
          (filterSelected) => filterSelected === filter
        )
      ) {
        action = "Remove";
      }
      if (name === "Survey name") {
        // setIsExpandFilter(false);
        //new method created for handling name group
        handleUpdateSurveyNameFilterSelected(
          id,
          index,
          action,
          filter,
          level,
          name
        );
      } else {
        handleUpdateFilterSelected(id, index, action, filter, level, name);
      }
    },
    [
      id,
      index,
      handleUpdateSurveyNameFilterSelected,
      // setIsExpandFilter,
      filterItem,
      handleUpdateFilterSelected,
      level,
      name,
    ]
  );

  return (
    <Box display="flex">
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        position="relative"
        style={style}
      >
        <Box
          className={
            // isQuestion ? "survey-filter-question 1111" : "survey-filter-name"
            filterItem.name !== "Survey name" ? "survey-filter-question" : "survey-filter-name"
          }
          style={{ minWidth: "120px" }}
        >
          {filterItem.name}
        </Box>

        <Box
          style={{
            position: "relative",
            width: width ?? filterItem.name !== "Survey name" ? "100%" : "90%",
          }}
          ref={refDropDownSurvey}
        >
          {filterItem.multiSelect ? (
            <MultiSimpleSelectFilter
              handleExpandFilter={handleExpandFilter}
              filtersSelected={filterItem.filtersSelected}
              isExpandFilter={isExpandFilter}
              filter={filterItem.name}
              operator={filterItem.operatorBetweenFiltersSelected}
              isQuestion={isQuestion}
            />
          ) : (
            <SelectSimpleFilter
              handleExpandFilter={handleExpandFilter}
              filtersSelected={filterItem.filtersSelected}
              isExpandFilter={isExpandFilter}
              filter={filterItem.name}
              borderColor="#DFE2E5"
              width="100%"
              isQuestion={isQuestion}
            />
          )}

          {isExpandFilter && (
            <DropDownSelectFilter
              isMulti={!filterItem.multiSelect}
              handleUpdateFilter={handleUpdateFilterClick}
              filters={filterItem.filters}
              filtersSelected={filterItem.filtersSelected}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyFilterNewRow;
