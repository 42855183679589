import { Box, Grid } from "@mui/material";
import {
  SubGroupFilterItem,
  OperatorType
} from "pages/TargetV2/types/targetV2.types";
import React, { FC, useState, useCallback } from "react";
import "../pastInteraction/PastInteractionSubGroupContainer.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import "../SubGroupFilterCustom.scss";
import OperatorLine from "../../components/operatorLine/OperatorLine";
import { useTranslation } from "react-i18next";
import SelectOperatorDropDown from "../../components/selectOperatorDropDown/SelectOperatorDropDown";
import SimpleDateRange from "../../filterRow/dateRange/SimpleDateRange";
import SurveyNameContainer from "./SurveyNameContainer";

type SurveyNewContainerProps = {
  parentFilterItem: SubGroupFilterItem;
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
  level: number;
  snapchot: any;
};

const SurveyNewContainer: FC<SurveyNewContainerProps> = ({
  parentFilterItem,
  subGroupFilterItem,
  index,
  level,
  snapchot,
}) => {

  const { t } = useTranslation();
  const {
    handleDeleteSubGroupFilterItem,
    arrayOfOperators,
    handleUpdateOperator,
    handleUpdateOperatorBetweenFiltersSelected,
  } = useTargetV2Context();


  const [expandedOperatorForAnswers, setExpandedOperatorForAnswers] = useState<
    number | null
  >(null);

  const [isExpandFilterOfOperator, setIsExpandFilterOfOperator] =
    useState<boolean>(false);



  const handleUpdateOperatorForSurveyNameClick = useCallback(
    (operator: string, i?: number) => {

      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      if (i !== undefined) {
       
        handleUpdateOperatorBetweenFiltersSelected(
          subGroupFilterItem.subgGroupFilterItemsChildren[0].subGroupId,
          i,
          operatorToAdd
        );
        setExpandedOperatorForAnswers(null);
      } 
    },
    [
      subGroupFilterItem,
      handleUpdateOperatorBetweenFiltersSelected,
      arrayOfOperators,
    ]
  );

  // const handleUpdateOperatorForAnswersClick = useCallback(
  //   (operator: string, i?: number) => {
  //     const operatorToAdd: OperatorType = arrayOfOperators.filter(
  //       (e) => e === operator
  //     )[0];
  //     if (i !== undefined) {
       
  //       handleUpdateOperatorBetweenFiltersSelected(
  //         subGroupFilterItem.subgGroupFilterItemsChildren[0]
  //           .subgGroupFilterItemsChildren[i].subGroupId,
  //         i,
  //         operatorToAdd
  //       );
  //       setExpandedOperatorForAnswers(null);
  //     } 
  //   },
  //   [
  //     handleUpdateOperatorBetweenFiltersSelected,
  //     subGroupFilterItem,
  //     arrayOfOperators,
  //   ]
  // );
 

  return (
    <>
    
    <Grid className="sub-group-custom-wrapper" key={index}>
      <Box display="flex" justifyContent="space-between" marginTop="8px">
        <Box display="flex" alignItems="center" gap="22px">
          <Box className="past-interaction-title-wrapper">
            <Box className="past-interaction-title">
              {subGroupFilterItem.name}
            </Box>
          </Box>
          {/* <Box className="operator-select">{t("select_your_operator")}</Box>

          <SelectOperatorDropDown
            operator={subGroupFilterItem.operator}
            values={arrayOfOperators}
            index={index}
            handleUpdateOperatorClick={handleUpdateOperatorClick}
            expandedFilterOfOperator={isExpandFilterOfOperator}
            setExpandedFilterOfOperator={setIsExpandFilterOfOperator}
          /> */}
        </Box>
        <CancelIcon
          onClick={() =>
            handleDeleteSubGroupFilterItem(subGroupFilterItem.parentId, index)
          }
          cursor="pointer"
          className="cancel-icon"
        />
      </Box>

      <Box>
        {subGroupFilterItem.filterItemsChildren.length > 0 && (
          <SimpleDateRange
            key={subGroupFilterItem.filterItemsChildren[0].filterId}
            filterItem={subGroupFilterItem.filterItemsChildren[0]}
            id={subGroupFilterItem.subGroupId}
            index={index}
            groupType={subGroupFilterItem.groupType}
          />
        )}

        {subGroupFilterItem.subgGroupFilterItemsChildren?.length > 0 &&
          subGroupFilterItem.subgGroupFilterItemsChildren.map(
            (outerSubGroup,surveyIndexVal) => (
              <>
             
                <Box
                  // key={outerSubGroup.subGroupId}
                  key={surveyIndexVal}
                  className="first-subgroup-container"
                  paddingBottom="44px"
                  paddingTop="44px"
                >
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="space-between"
                    maxWidth="95%"
                    key={surveyIndexVal}
                  >
                    {/* Surveyname top group including oprator and cancel icon */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginTop="8px"
                      marginBottom="20px"
                      key={surveyIndexVal}
                    >
                      <Box display="flex" alignItems="center" gap="22px">
                        <Box className="operator-select">
                          {t("select_your_operator_survey")}
                        </Box>
                        <SelectOperatorDropDown
                          operator={outerSubGroup.operator}
                          values={arrayOfOperators}
                          index={index}
                          handleUpdateOperatorClick={
                            handleUpdateOperatorForSurveyNameClick
                          }
                          expandedFilterOfOperator={isExpandFilterOfOperator}
                          setExpandedFilterOfOperator={
                            setIsExpandFilterOfOperator
                          }
                        />
                      </Box>
                      {/* <CancelIcon
                        onClick={() =>
                          handleDeleteSubGroupFilterItem(
                            subGroupFilterItem.parentId,
                            index
                          )
                        }
                        cursor="pointer"
                        className="cancel-icon"
                      /> */}
                    </Box>
                    {/* end of Surveyname top group including oprator and cancel icon */}
                    {outerSubGroup.subgGroupFilterItemsChildren?.length > 0 &&
                      outerSubGroup.subgGroupFilterItemsChildren.map(
                        (subGroup, indexVal) => (
                          <>
                            {/* {console.log("subgroup subgroup::", subGroup)} */}
                            {/* {console.log("subGroupFilterItem operator::", outerSubGroup)} */}
                            {/*  Operator between diff survey group*/}
                            {indexVal > 0 && (
                              <Box marginTop="24px" marginBottom="24px">
                                <OperatorLine
                                  operator={outerSubGroup.operator}
                                  width="84%"
                                />
                              </Box>
                            )}
                            {/*  end of Operator between diff survey group*/}
                            
                            
                              <SurveyNameContainer
                                  subGroupFilterItem={subGroupFilterItem}
                                  subGroup={subGroup}
                                  indexVal={indexVal}
                                  level={level}
                                  snapchot={snapchot}         
                                />
                          </>
                        )
                      )}
                  </Box>
                </Box>
              </>
            )
          )}
      </Box>
    </Grid>
    </>
  );
};

export default SurveyNewContainer;
